Navbar.css


.navbar{

}
.navbar-list {


}

.navbar-item {
    position: relative;
  text-decoration: none;
  color: black;
  margin-right: 20px;
  margin-left:20px;
  padding:10px;
  color: #748695;
  font-size: 2.5vh;

  }

  
  @media (max-width: 900px) {
    .navbar-item {
      font-size: 4vh; /* Adjust the font size for mobile screens */
    }
  }
  .navbar-items-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
   
  }
  
  .navbar-item::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #748695;
    transition: width 0.3s ease-in-out;
  }
  
  .navbar-item:hover::before {
    width: 100%;
  }
  .navbar-menu-icon {
    margin-left: auto;
    justify-content:'right';
    padding:40px;
    padding-right:80px;

  }

  .navbar-item.show-underline::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #748695;
    animation: underline-disappear 1s forwards;
    transition: width 0.3s ease-in-out;
  }
  .navbar-item:hover::before {
    animation: none; /* Disable the animation on hover */
    width: 100%;
    transition: width 0.3s ease-in-out;
  }
  @keyframes underline-disappear {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }
  .white-font {
    color: white;
  }

  .navbar-item.white-font.show-underline::before {
    background-color: white;
  }