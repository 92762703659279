/* Home.css */
h1 {
    font-size: 8rem;
    display: flex;
    align-items: center;
    color: black; /* Add this line to set the font color */
  }
.dot-container {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    font:'black';
  }
  
  .dot-container::before {
    content: "";
    color:'black';
    position: absolute;
    bottom: -3.5rem;
    left: 2.5rem;
    transform: translate(-30%, -50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #333333;
    animation: radiate 1.5s infinite ease-out;
  }

  .dot-container-2 {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    font:'black';
  }
  
  .dot-container-2::before {
    content: "";
    color:'black';
    position: absolute;
    bottom: -3.5rem;
    left: 2.5rem;
    transform: translate(-30%, -50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #333333;
    animation: radiate 1.5s infinite ease-out;
  }
  
  @keyframes radiate {
    0% {
      transform: translate(-20%, -50%) scale(2.5);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(4);
      opacity: 0.6;
    }
  }
  

  /* Email */
  .email-input-container {



    margin-left:150px;
    transform: translate(-50%, -50%);
   
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1); /* Add box shadow */
  }
  
  .email-input-container input[type="text"] {
    /* Adjust the padding to make space for the icon */
    font-size: 1.5rem;
    padding:  20px;
    padding-left: 65px;
    width: 500px;
    max-width: 100%;
}
  
  .email-input-container .email-icon {
    position: absolute;
    
    left: 20px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  

  .home-container {
    minHeight: calc(100vh + 20px);
    display: flex;
    flexDirection: column;
    alignItems: left;
    marginTop: -20px;
  }
  
  .content-container {
    flex: 1;
    display: flex;
    flexDirection: column;
    alignItems: center;
  }
  
  .text-container {
    display: flex;
    flexDirection: column;
    alignItems: flex-start;
  }
  
  .title {
    backgroundColor: '';
    fontSize: 20vh;
    marginBottom: 2rem;
    color: black;
    textAlign: left;
  }
  
  .subtitle {
    backgroundColor: '';
    fontSize: 2rem;
    marginTop: 0;
    color: black;
    textAlign: left;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .title {
      fontSize: 12vh; /* Adjust the font size for mobile screens */
    }
  
    .subtitle {
      fontSize: 1.5rem; /* Adjust the font size for mobile screens */
    }
  }
  