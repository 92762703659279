/* Portfolio.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Garamond&display=swap');
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Mono", monospace;
  color: #BCC1CA;
}

p {
    color: #BCC1CA;
    font-family: 'Garamond', serif;
    font-size: 1.2vw;
    line-height: 2vw
    
}

.content-block {
    width: 80%;


  }

  .content-block2 {
    width: 60%;
    margin-left: 10%;


  }
  
  .paragraph {
    margin-top: 1vw;
 
  }
  .content-container {
    display: flex;


    
  }

  .content-container1 {
    display: flex;
    height:40%;


    
  }

  .main-div {
 
  }

  .content-container-left {
    display: flex;
    height:300px;
    
  }

  .modal {
    /* Modal styles... */
    opacity: 1;
    transition: opacity 0.6s ease-in;
  }
  
  .modal-open {
    opacity: 1;
  }
 
  .portfolio-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
  .background-container {
    background-color: #1D218;
    width: 100%;
    height: 100vh;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 20%;
    overflow: hidden;
  }
  



  .row {
    display: flex;
    flex: 1;
  }

  .row-70 {
    background-color: #f0f0f0; /* Just for visualization */
    height: 70%;
  }

  .row-30 {
    background-color: #e0e0e0; /* Just for visualization */
    height: 30%;
  }
  .Portfolio {
  box-sizing: border-box;
}

@media (max-width: 768px) {
    .youtube-wrapper {
      height: 100vh;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .youtube-wrapper {
      height: 10vh;
    }
  }
  
  @media (min-width: 1025px) {
    .youtube-wrapper {
      height: 150vh;
    }
  }
  