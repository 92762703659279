/* ContactUs.css */
h1 {
    font-size: 8rem;
    display: flex;
    align-items: center;
    color: black; /* Add this line to set the font color */
  }



  .dot-container2 {
    position: relative;
    width: 60px;
    height: 60px;
    font:'black';
  }
  
  .dot-container2::before {
    content: "";
    color:'black';
    position: absolute;
    bottom: -30%;
    left: 60%;
    transform: translate(-30%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333333;
    animation: radiate 1.5s infinite ease-out;
  }
.dot-container {
    position: relative;
    width: 100px;
    height: 100px;
    font:'black';
  }
  
  .dot-container::before {
    content: "";
    color:'black';
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-30%, -50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333333;
    animation: radiate 1.5s infinite ease-out;
  }
  
  @keyframes radiate {
    0% {
      transform: translate(-20%, -50%) scale(2.5);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(4);
      opacity: 0.6;
    }
  }
  
  .email-input-container {
    margin-left: 33%;
    padding-right: 50px; /* Increase the right padding to accommodate the send icon */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
    position: relative; /* Add position relative */
  }
  
  .email-input-container input[type="text"] {
    font-size: 1.5rem;
    padding: 20px;
    padding-left: 75px;
    padding-right: 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    width: 100%; /* Make the input expand to fill the container */
  
  }
  
  .email-input-container .email-icon {
    position: absolute;
    left: 20px; /* Position the email icon on the left side */

    top: 50%;
    transform: translateY(-50%);
  }
  
  .email-input-container .send-icon {
    position: absolute;
    right: 0px; /* Position the send icon on the right side */
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem;
    padding:1vh;
    border-radius:25%;
    transition: box-shadow 0.3s ease; /* Add transition effect for smooth animation */
  }
  
  .email-input-container .send-icon:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9); /* Add shadow effect on hover */


  }
  
